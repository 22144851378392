<template>
  <div>
    <working-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="siteContentObserver">
          <v-container fluid>
            <v-row class="d-none">
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="items.update_type.text"
                  :rules="items.update_type.rules"
                >
                  <v-select
                    ref="update_type"
                    v-model="site_content_config.update_type"
                    name="update_type"
                    :error-messages="errors"
                    :success="valid"
                    :items="update_type_items"
                    :no-data-text="items.update_type.text"
                    :label="items.update_type.text"
                    outlined
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  class="mt-0"
                  v-model="site_content_config.girl_notime_list_flag"
                  mandatory
                  row
                >
                  <v-radio label="新着に掲載する" value="1"></v-radio>
                  <v-radio label="しない" value="0"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </working-site-card>
  </div>
</template>

<script>
import { set, reactive, toRefs, defineComponent } from "@vue/composition-api";
import setting from "@/common/setting.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const state = reactive({
      site_content_config: {},
      items: [],
    });

    const update_type_items = [
      { value: 1, text: "通常更新：待機中キャストを一括で更新" },
      {
        value: 2,
        text: "高頻度更新：1分間隔で待機中キャスト2名をランダムに更新",
      },
    ];

    const init = async () => {
      // 個別項目の設定を取得
      state.items = setting.working_status_config.find(
        (item) => item.site_content_id === props.com_data.site_content_id
      ).items;

      // state.site_content_configにキーを設定
      for (let key in state.items) {
        set(state.site_content_config, key, null);
      }
      if (props.com_data.site_content_config) {
        Object.keys(props.com_data.site_content_config).forEach(function (key) {
          set(
            state.site_content_config,
            key,
            props.com_data.site_content_config[key]
          );
        });
      }
      // 初期値を設定する
      if (state.site_content_config["update_type"] == null) {
        set(state.site_content_config, "update_type", 1);
      }
      if (state.site_content_config["girl_notime_list_flag"] == null) {
        set(state.site_content_config, "girl_notime_list_flag", 1);
      }
      // console.log(state.site_content_config);
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      return await ctx.refs.siteContentObserver.validate();
    };

    const getConfig = async () => {
      return state.site_content_config;
    };

    return {
      props,
      setting,
      update_type_items,
      ...toRefs(state),
      checkValid,
      getConfig,
    };
  },
});
</script>

<style scoped></style>
