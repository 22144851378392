<template>
  <div>
    <v-app-bar flat :color="is_auto ? 'amber lighten-3' : 'white'">
      <div v-if="is_auto" class="pa-2">
        <v-btn class="font-weight-bold error" @click="onUpdateButton"
          ><v-icon left>mdi-timer-off-outline</v-icon>自動更新中(停止)</v-btn
        >
      </div>
      <div v-else class="pa-2">
        <v-btn class="font-weight-bold info" @click="onUpdateButton"
          ><v-icon left>mdi-timer-outline</v-icon>自動更新を開始</v-btn
        >
      </div>
      <div class="pa-2">
        <v-btn class="font-weight-bold info" @click="onAllSyncButton"
          ><v-icon left>mdi-sync</v-icon>一括更新</v-btn
        >
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from "@vue/composition-api";

export default {
  props: ["is_auto"],
  setup(props, ctx) {
    const onUpdateButton = async () => {
      ctx.emit("update_button");
    };
    const onAllSyncButton = async () => {
      ctx.emit("sync_button");
    };

    return {
      onUpdateButton,
      onAllSyncButton,
    };
  },
};
</script>
