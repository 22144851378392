var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('working-site-card',{attrs:{"com_data":_vm.com_data},scopedSlots:_vm._u([{key:"item_edit",fn:function(){return [_c('ValidationObserver',{ref:"siteContentObserver"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-none"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.update_type.text,"rules":_vm.items.update_type.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{ref:"update_type",attrs:{"name":"update_type","error-messages":errors,"success":valid,"items":_vm.update_type_items,"no-data-text":_vm.items.update_type.text,"label":_vm.items.update_type.text,"outlined":""},model:{value:(_vm.site_content_config.update_type),callback:function ($$v) {_vm.$set(_vm.site_content_config, "update_type", $$v)},expression:"site_content_config.update_type"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.gidi_discount_time.text,"rules":_vm.items.gidi_discount_time.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"gidi_discount_time",attrs:{"name":"gidi_discount_time","error-messages":errors,"success":valid,"label":_vm.items.gidi_discount_time.text,"suffix":"分","outlined":"","type":"number"},model:{value:(_vm.site_content_config.gidi_discount_time),callback:function ($$v) {_vm.$set(_vm.site_content_config, "gidi_discount_time", $$v)},expression:"site_content_config.gidi_discount_time"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.gidi_discount_price.text,"rules":_vm.items.gidi_discount_price.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"gidi_discount_price",attrs:{"name":"gidi_discount_price","error-messages":errors,"success":valid,"label":_vm.items.gidi_discount_price.text,"suffix":"円","outlined":"","type":"number"},model:{value:(_vm.site_content_config.gidi_discount_price),callback:function ($$v) {_vm.$set(_vm.site_content_config, "gidi_discount_price", $$v)},expression:"site_content_config.gidi_discount_price"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.gidi_dlt.text,"rules":_vm.items.gidi_dlt.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{ref:"gidi_dlt",attrs:{"name":"gidi_dlt","error-messages":errors,"success":valid,"items":_vm.gidi_dlt_items,"no-data-text":_vm.items.gidi_dlt.text,"label":_vm.items.gidi_dlt.text,"outlined":""},model:{value:(_vm.site_content_config.gidi_dlt),callback:function ($$v) {_vm.$set(_vm.site_content_config, "gidi_dlt", $$v)},expression:"site_content_config.gidi_dlt"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.gidi_info_text.text,"rules":_vm.items.gidi_info_text.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{ref:"gidi_info_text",attrs:{"name":"gidi_info_text","error-messages":errors,"success":valid,"label":_vm.items.gidi_info_text.text,"rows":"4","outlined":"","hint":"※未設定の場合、媒体に表示される初期値が適用されます。","persistent-hint":"","background-color":"white"},model:{value:(_vm.site_content_config.gidi_info_text),callback:function ($$v) {_vm.$set(_vm.site_content_config, "gidi_info_text", $$v)},expression:"site_content_config.gidi_info_text"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"mandatory":"","row":""},model:{value:(_vm.site_content_config.gidi_list_flag),callback:function ($$v) {_vm.$set(_vm.site_content_config, "gidi_list_flag", $$v)},expression:"site_content_config.gidi_list_flag"}},[_c('v-radio',{attrs:{"label":"新着に掲載する","value":"1"}}),_c('v-radio',{attrs:{"label":"しない","value":"0"}})],1)],1)],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }